<template>
    <Component :is="icon" class="shrink-0" aria-hidden="true" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import * as Solid16 from '@heroicons/vue/16/solid';
import * as Solid20 from '@heroicons/vue/20/solid';
import * as Outline24 from '@heroicons/vue/24/outline';
import * as Solid24 from '@heroicons/vue/24/solid';

const props = withDefaults(
    defineProps<{
        name: string;
        mini?: boolean;
        micro?: boolean;
        solid?: boolean;
    }>(),
    {
        mini: false,
        micro: false,
        solid: false,
    },
);

const icon = computed(
    // @ts-ignore
    () => (props.micro ? Solid16 : props.mini ? Solid20 : props.solid ? Solid24 : Outline24)[`${props.name}Icon`],
);
</script>
