<template>
    <Head :title="title" />
    <Banner />
    <div class="min-h-screen bg-gray-50 pb-6">
        <Disclosure as="header" class="bg-white shadow" data-test="navbar" v-slot="{ open }">
            <div class="mx-auto max-w-screen-2xl px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
                <div class="relative flex h-16 justify-between">
                    <div class="relative z-10 flex px-2 lg:px-0">
                        <div class="flex flex-shrink-0 items-center gap-4">
                            <ProgramMenu />
                        </div>
                    </div>
                    <div class="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0"></div>
                    <div class="relative z-10 flex items-center lg:hidden">
                        <DisclosureButton
                            class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            data-test="mobile-nav-open-button"
                        >
                            <span class="sr-only">Open menu</span>
                            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                        </DisclosureButton>
                    </div>
                    <div class="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                        <span class="text-sm text-gray-500" data-test="user-info">
                            {{ $page.props.auth.user.name || $page.props.auth.user.email }}
                        </span>
                        <Menu as="div" class="relative ml-4 flex-shrink-0">
                            <div>
                                <MenuButton
                                    class="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    data-test="user-menu"
                                >
                                    <span class="sr-only">Open user menu</span>
                                    <UserCircleIcon class="h-8 w-8 text-gray-500" />
                                </MenuButton>
                            </div>
                            <transition
                                enter-active-class="transition ease-out duration-75"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <MenuItems
                                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                    <MenuItem v-slot="{ active }">
                                        <Link
                                            :href="route('profile.show')"
                                            :class="[
                                                'block w-full px-4 py-2 text-left text-sm text-gray-700',
                                                { 'bg-gray-100': active },
                                            ]"
                                        >
                                            Profile
                                        </Link>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <Link
                                            as="button"
                                            :href="route('logout')"
                                            method="post"
                                            :class="[
                                                'block w-full px-4 py-2 text-left text-sm text-gray-700',
                                                { 'bg-gray-100': active },
                                            ]"
                                            type="button"
                                            data-test="logout-button"
                                        >
                                            Log out
                                        </Link>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
                <nav class="hidden lg:flex lg:space-x-8 lg:py-2" aria-label="Global">
                    <Link
                        :href="route('dashboard')"
                        class="inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                        :class="{ 'bg-gray-100 text-gray-900': route().current('dashboard') }"
                        data-test="menu-button-home"
                    >
                        Home
                    </Link>
                    <Link
                        v-for="item in navigation"
                        :key="item.name"
                        :href="item.href"
                        class="inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                        :class="{ 'bg-gray-100 text-gray-900': item.active }"
                        :data-test="`menu-button-${item.href}`"
                    >
                        {{ item.name }}
                    </Link>
                </nav>
            </div>

            <DisclosurePanel as="nav" class="lg:hidden" aria-label="Global" v-slot="{ close }">
                <div class="space-y-1 px-2 pb-3 pt-2">
                    <DisclosureButton
                        :as="Link"
                        :href="route('dashboard')"
                        class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                        :class="{ 'bg-gray-100 text-gray-900': route().current('dashboard') }"
                    >
                        Home
                    </DisclosureButton>
                    <DisclosureButton
                        v-for="item in navigation"
                        :key="item.name"
                        :as="Link"
                        :href="item.href"
                        class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                        :class="{ 'bg-gray-100 text-gray-900': item.active }"
                        :data-test="`mobile-nav-button-${item.name}`"
                    >
                        {{ item.name }}
                    </DisclosureButton>
                </div>
                <div class="border-t border-gray-200 pb-3 pt-4">
                    <div class="flex items-center px-4">
                        <div class="flex-shrink-0">
                            <UserCircleIcon class="h-10 w-10 text-gray-500" />
                        </div>
                        <div class="ml-3">
                            <!-- <div class="text-base font-medium text-gray-800">{{ user.name }}</div> -->
                            <div class="text-sm text-gray-500">
                                {{ $page.props.auth.user.name || $page.props.auth.user.email }}
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 space-y-1 px-2">
                        <DisclosureButton>
                            <Link
                                :href="route('profile.show')"
                                class="flex w-full rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                            >
                                Profile
                            </Link>
                        </DisclosureButton>
                        <DisclosureButton>
                            <Link
                                as="button"
                                :href="route('logout')"
                                method="post"
                                class="flex w-full rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                data-test="mobile-logout-button"
                            >
                                Log out
                            </Link>
                        </DisclosureButton>
                    </div>
                </div>
            </DisclosurePanel>
        </Disclosure>
        <main class="mx-auto mt-6 flex max-w-screen-2xl flex-col gap-4 px-2 sm:px-4 lg:px-8">
            <slot />
        </main>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import { UserCircleIcon } from '@heroicons/vue/24/solid';
import { Head, Link, usePage } from '@inertiajs/vue3';
import Banner from '@/Components/Banner.vue';
import ProgramMenu from '@/Components/ProgramMenu.vue';

defineProps<{
    title?: string;
}>();

const page = usePage();

const navigation = computed(() =>
    [
        { name: 'Projects', href: route('projects.index'), active: route().current('projects.*') },
        {
            name: 'Aggregators',
            href: route('aggregators.index'),
            active: route().current('aggregators.*'),
            visible: page.props.can.viewAny.aggregator,
        },
        {
            name: 'Users',
            href: route('users.index'),
            active: route().current('users.*'),
            visible: page.props.auth.anyAdmin,
        },
        {
            name: 'Load Curves',
            href: route('loadCurves.index'),
            active: route().current('loadCurves.*'),
            visible: page.props.can.viewAny.loadCurve,
        },
        {
            name: 'Data Upload',
            href: route('savingsUploads.index'),
            active: route().current('savingsUploads.*'),
            visible: page.props.auth.admin,
        },
        {
            name: 'Payments',
            href: route('payments'),
            active: route().current('payments'),
        },
        {
            name: 'Payment Admin',
            href: route('payments.index'),
            active: route().current('payments.*'),
            visible: page.props.auth.anyAdmin,
        },
        {
            name: 'Reports',
            href: route('reports'),
            active: route().current('reports'),
            visible: page.props.auth.anyAdmin,
        },
    ].filter((item) => item.visible !== false),
);
</script>
