import { reactive } from 'vue';
import type { PendingVisit } from '@inertiajs/core';

export const navigation = reactive<{
    autoSaveFieldProcessing: boolean;
    pendingVisit: PendingVisit | null;
}>({
    autoSaveFieldProcessing: false,
    pendingVisit: null,
});
